﻿@import 'color-vars';

$opacity-factor: 0.6;
@mixin color-class($name, $base-color)
{
    .#{$name} {
        background-color: $base-color;
    }
    .#{$name}-bb {
        background-color: $base-color;
        border-color: $base-color;
    }

    .#{$name}-bb-transparent {
        background-color: rgba($base-color, $opacity-factor);
        border-color: rgba($base-color, $opacity-factor);
    }

    .#{$name}-text {
      color: $base-color;
    }
    .#{$name}-fill {
      fill: $base-color;
    }
    .#{$name}-stroke {
      stroke: $base-color;
    }
}

@each $name, $base-color in $colormap {
    @include color-class($name, $base-color)
}
